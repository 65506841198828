<template>
	<div class="account-pages my-5 pt-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card overflow-hidden">
						<div class="bg-primary">
							<div class="text-primary text-center p-4">
								<h5 class="text-white font-size-20" id="loginTitle">Login</h5>
								<p class="text-white-50">Login to the Turbo Time Tracker dashboard.</p>
								<a @click.prevent="$router.push({ name: 'home' })" class="logo logo-admin">
									<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
								</a>
							</div>
						</div>
						<div class="card-body p-4" id="test">
							<div class="pt-3 pl-3 pr-3">
								<b-alert
									v-model="isAuthError"
									variant="danger"
									class="mt-4 text-center"
									id="authError"
								>{{authError}}</b-alert>

								<b-form @submit.prevent="login" class="form-horizontal mt-4" id="loginForm">
									<b-form-group id="input-group-1" label="Email" label-for="email">
										<b-form-input
											id="email"
											name="hello"
											v-model="email"
											type="email"
											placeholder="Enter email"
											:disabled="isLoggingIn"
											:class="{ 'is-invalid': $v.email.$error }"
											@input="$v.email.$touch"
											v-focus
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
										<div class="invalid-feedback" v-else-if="!$v.email.email && $v.email.$dirty"> This is not a valid email </div>
									</b-form-group>

									<b-form-group
										id="input-group-2"
										label="Password"
										label-for="password"
										class="mb-3"
									>
										<b-form-input
											id="password"
											v-model="password"
											type="password"
											placeholder="Enter password"
											:disabled="isLoggingIn"
											:class="{ 'is-invalid': $v.password.$error }"
											@input="$v.password.$touch"
											@blur="$v.password.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.password.required && $v.password.$dirty">Field is required</div>
									</b-form-group>

									<div class="form-group row">
										<div class="col-sm-12 text-center">
											<b-button variant="primary" class="w-md" id="loginBtn" type="submit" :disabled="isLoggingIn">
												<span v-if="!isLoggingIn">Login</span>
												<i v-else class="fas fa-spin fa-circle-notch"></i>
											</b-button>
										</div>
									</div>

									<div class="form-group mt-2 mb-0 row">
										<div class="col-12 text-center">
											<router-link tag="a" to="/request-password-reset">
												<i class="mdi mdi-lock"></i> Forgotten your password?
											</router-link>
										</div>
									</div>
								</b-form>
							</div>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
					<div class="mt-3 text-center">
						<p>
							<router-link to="/sign-up" class="font-weight-medium">
								Register Now
							</router-link>
						</p>
					</div>
					<MiniFooter />
				</div>
				<!-- end col -->
			</div>
			<!-- end row -->
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";
import { required, email } from 'vuelidate/lib/validators'
import { apiPost, apiGet } from '@/utils/api'
import MiniFooter from '@/components/account/mini-footer'; 

export default {
	name: 'Login',
	page: {
		title: "Login",
		meta: [{ name: "description", content: appConfig.description }],
	},
	components: {
		MiniFooter,
	},
	directives: {
		focus: {
			inserted: (el) => {
				el.focus()
			}
		}
	},
	data() {
		return {
			email: '',
			password: '',
			authError: null,
			isLoggingIn: false,
			isAuthError: false
		};
	},
	methods: {
		async login() { // use async await to make the login method testable
			// Reset the authError if it existed.
			this.authError = null;
			this.isLoggingIn = true;

			this.$v.$touch()

			if (this.$v.$invalid) {
				this.isLoggingIn = false;
				this.authError = "Please make sure all fields are filled in correctly."
				this.isAuthError = true
				return
			}

			var data = {
				username: this.email,
				password: this.password
			}

			await apiPost('login', {
				...data
				})
				.then(async (response) => {
					this.isLoggingIn = false;
					this.isAuthError = false;
					this.$store.dispatch('auth/setAuthToken', response.data.access_token)
					await apiGet('user')
						.then((response) => {
							let userType = response.data.type
							this.$store.dispatch('user/refreshUserInfo')
							if (userType === 'user' || userType === 'demo') {
								this.$router.push('client/home')
							} else if (userType === 'admin' || userType === 'super_admin') {
								this.$router.push('admin/dashboard')
							}
						})
						.catch((error) => {
							// eslint-disable-next-line
							console.error('Error:', error);
							this.isLoggingIn = false;
							this.authError = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : "An unexpected error occurred. If the problem persists, please contact support."
							this.isAuthError = true;
						})
				})
				.catch((error) => {
					// eslint-disable-next-line
					console.error('Error:', error);
					this.isLoggingIn = false;
					this.authError = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : "An unexpected error occurred. If the problem persists, please contact support."
					this.isAuthError = true;
				})
		}
	},
	validations: {
		email: { required, email },
		password: { required }
	}
};
</script>